import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService, HttpMethod } from '../api.service';
import { Invite } from './invite.model';
import { InviteResponse } from './invite-response.model';
import { InviteApprovalResponse } from './invite-approval-response.model';
import { Vendor } from '../vendor/vendor.model';

@Injectable({
    providedIn: 'root'
})
export class InviteApiService extends ApiService
{
    constructor(protected http: HttpClient)
    {
        super(http);
    }

    getInvites(): Observable<Invite[]>
    {
        return this.apiRequest<any[]>(this.getInvitesUrl, HttpMethod.Get)
            .pipe(map(json => json.map(item => new Invite(item))));
    }

    getInvite(uniqueToken: string): Observable<Invite>
    {
        return this.apiRequest<any[]>(this.getInviteUrl(uniqueToken), HttpMethod.Get)
            .pipe(map(json => new Invite(json)));
    }

    createInvite(invite: Invite): Observable<Invite>
    {
        return this.apiRequest(this.createOrUpdateInviteUrl, HttpMethod.Post, JSON.stringify(invite))
            .pipe(map(json => new Invite(json)));
    }

    updateInvite(invite: Invite): Observable<Invite>
    {
        return this.apiRequest(this.createOrUpdateInviteUrl, HttpMethod.Put, JSON.stringify(invite))
            .pipe(map(json => new Invite(json)));
    }

    deleteInvite(inviteId: number): Observable<null>
    {
        return this.apiRequest(this.deleteInviteUrl(inviteId), HttpMethod.Delete);
    }

    sendInviteResponse(response: InviteResponse): Observable<Response>
    {
        return this.apiRequest(this.sendInviteResponseUrl, HttpMethod.Post, JSON.stringify(response));
    }

    approveInvite(response: InviteApprovalResponse): Observable<Vendor>
    {
        return this.apiRequest(this.approveInviteUrl, HttpMethod.Post, JSON.stringify(response))
            .pipe(map(json => new Vendor(json)));
    }

    // updateHedge(hedge: Hedge): Observable<Hedge>
    // {
    //     return this.apiRequest(this.updateHedgeUrl, HttpMethod.Put, JSON.stringify(hedge))
    //         .pipe(map(json => new Hedge(json)));
    // }
    //
    // deleteHedge(hedgeId: number): Observable<null>
    // {
    //     return this.apiRequest(this.deleteHedgeUrl(hedgeId), HttpMethod.Delete);
    // }

    // =============================================================================================================================
    // URLs
    // =============================================================================================================================
    /* tslint:disable:member-ordering */
    // tslint:disable:max-line-length
    private get baseUrl(): string { return this.baseApiUrl + 'invites'; }
    private getInviteUrl(uniqueToken: string): string { return `${this.baseUrl}?token=${uniqueToken}`; }
    private get getInvitesUrl(): string { return `${this.baseUrl}/all`; }
    private get createOrUpdateInviteUrl(): string { return `${this.baseUrl}`; }
    private deleteInviteUrl(id: number): string { return `${this.baseUrl}/${id}`; }
    private get sendInviteResponseUrl(): string { return `${this.baseUrl}/response`; }
    private get approveInviteUrl(): string { return `${this.baseUrl}/approve`; }
}
