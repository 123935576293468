export enum InviteStatus
{
    Sent,
    PendingApproval,
    Approved
}

export class Invite
{
    public id: number;
    public firstName: string;
    public lastName: string;
    public email: string;
    public createdBy: string;
    public locationId: number;
    public locationName: string;
    public notes: string;
    public isExistingVendor = false;
    public status: InviteStatus;
    public vendorName: string;
    public vendorAddress: string;
    public vendorCity: string;
    public vendorState: string;
    public vendorZip: string;
    public vendorLicenseImageUrl: string;

    constructor(json: any = null)
    {
        if (json)
        {
            Object.assign(this, json);
        }
    }
}
