import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiService, HttpMethod } from '../api.service';
import { map } from 'rxjs/operators';
import { MiscellaneousPartsList } from './miscellaneous-parts-list.model';

@Injectable({
    providedIn: 'root'
})
export class MiscellaneousPartApiService extends ApiService
{
    constructor(protected http: HttpClient)
    {
        super(http);
    }

    getAll(): Observable<MiscellaneousPartsList>
    {
        return this.apiRequest<any>(this.getAllPartsUrl, HttpMethod.Get).pipe(map(json => new MiscellaneousPartsList(json)));
    }

    uploadMiscellaneousPartsFile(formData: FormData, getStatistics: boolean, deleteMissingItems: boolean = false): Observable<any>
    {
        const uploadUrl = this.uploadMiscellaneousPartsFileUrl(getStatistics, deleteMissingItems);
        return this.apiUploadRequest(uploadUrl, formData, 60);
    }

    // =============================================================================================================================
    // URLs
    // =============================================================================================================================
    /* tslint:disable:member-ordering */
    private get baseUrl(): string { return this.baseApiUrl + 'miscellaneousParts'; }
    private get getAllPartsUrl(): string { return `${this.baseUrl}?sortField=partnumber`; }
    private uploadMiscellaneousPartsFileUrl(getStatistics: boolean, deleteMissingItems: boolean): string
    {
        return `${this.baseUrl}/upload?getStatistics=${getStatistics}&deleteMissingItems=${deleteMissingItems}`;
    }
}
