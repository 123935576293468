import { MiscellaneousPart } from './miscellaneous-part.model';

export class MiscellaneousPartsList
{
    public parts: MiscellaneousPart[] = [];
    public count: number;

    constructor(json: any)
    {
        if (json)
        {
            // Map the json object to 'this' objects properties.
            Object.assign(this, json);

            if (json.parts) this.parts = json.parts.map(partJson => new MiscellaneousPart(partJson));
        }
    }
}
