import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MiscellaneousPartApiService } from './miscellaneous-part-api.service';
import { MiscellaneousPartsList } from './miscellaneous-parts-list.model';

@Injectable({
    providedIn: 'root'
})
export class MiscellaneousPartManagerService
{
    constructor(private miscellaneousPartApiService: MiscellaneousPartApiService)
    {
    }

    getAll(): Observable<MiscellaneousPartsList>
    {
        return this.miscellaneousPartApiService.getAll();
    }

    uploadMiscellaneousPartsFile(file: File, getStatistics: boolean, deleteMissingItems: boolean = false): Observable<any>
    {
        const formData = new FormData();
        formData.append('0', file, file.name);
        return this.miscellaneousPartApiService.uploadMiscellaneousPartsFile(formData, getStatistics, deleteMissingItems);
    }
}
