import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';

import { AuthManagerService } from '../auth/auth-manager.service';
import { InviteApiService } from './invite-api.service';
import { Invite } from './invite.model';
import { InviteResponse } from './invite-response.model';
import { InviteApprovalResponse } from './invite-approval-response.model';
import { Vendor } from '../vendor/vendor.model';
import { HttpMethod } from '../api.service';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class InviteManagerService
{

    constructor(private authService: AuthManagerService, private inviteApiService: InviteApiService)
    {
    }

    getInvites(): Observable<Invite[]>
    {
        return this.inviteApiService.getInvites();
    }

    getInvite(uniqueToken: string): Observable<Invite>
    {
        return this.inviteApiService.getInvite(uniqueToken);
    }

    createInvite(invite: Invite): Observable<Invite>
    {
        return this.inviteApiService.createInvite(invite);
    }

    updateInvite(invite: Invite): Observable<Invite>
    {
        return this.inviteApiService.updateInvite(invite);
    }

    deleteInvite(inviteId: number): Observable<null>
    {
        return this.inviteApiService.deleteInvite(inviteId);
    }

    sendInviteResponse(response: InviteResponse): Observable<Response>
    {
        return this.inviteApiService.sendInviteResponse(response);
    }

    approveInvite(response: InviteApprovalResponse): Observable<Vendor>
    {
        return this.inviteApiService.approveInvite(response);
    }
}
